<template>
   <div class="form">
    <el-form class="content bascform" ref="form" :model="form" label-width="100px">
      <div class="col col4">
        <el-form-item label="序号" prop="aaa">
            <el-input  v-model="form.aaa "></el-input>
        </el-form-item>
        <el-form-item label="评分项" prop="aaa">
            <el-input v-model="form.aaa "></el-input>
        </el-form-item>
        <el-form-item label="基准分" prop="aaa">
            <el-input  v-model="form.aaa "  ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'EditAddscoreDialog',
  data: function () {
    return {
      form: {
        aaa: ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
.form{
    :deep(.el-input__inner){
        width: 50%;
    }
}
</style>
